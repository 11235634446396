import React from "react"
import Card from "../../components/card/card.component"
import SEO from "../../components/seo"
import { Container } from "./about.styles"
import { useStaticQuery, graphql } from "gatsby"

const Plantation = () => {
  const { mdx } = useStaticQuery(graphql`
    query PlantationQuery {
      mdx(frontmatter: { title: { eq: "Green Yoga Plantation Project" } }) {
        id
        body
        frontmatter {
          id
          title
          buttontext
          featuredimage {
            src {
              childImageSharp {
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Green Yoga Plantation Project" />

      <Container>
        {mdx && (
          <Card data={mdx}>
            {/*   <CustomButton onClick={() => navigate("/about/plantation")}>
              {mdx.frontmatter.buttontext}
            </CustomButton> */}
          </Card>
        )}
      </Container>
    </>
  )
}

export default Plantation
